/*
Author: Yunus
*/

// as the page loads, call these scripts
jQuery(document).ready(function($) {
  
    var cbt_woo = {

        init: function () {
            var _self = this;
            _self.productsLoop();
            _self.options_modal();
            _self.variable_product_ajax_cart();
        },

      productsLoop: function () {
            var _self = this;

           // prevent from going to single page on click
           // instead open modal
           $('li.type-product .woocommerce-LoopProduct-link').click(function () {
                var elm = $(this).closest('li.type-product').find('.cbt-modal-open');
                if(elm.length > 0)
                {
                    elm.click();
                    return false;
                }
            });

           // close modal and add loading on add to cart btn
            $( document.body ).on( 'adding_to_cart', function(){
                var elm = $('li.type-product.cbt-modal-opened');
                $('.cbt-modal-close', elm).click();
                $('.cbt-tm-add-to-cart', elm).addClass('loading');
            });

            // remove loading on add to cart and show notification
            $( document.body ).on( 'added_to_cart', function(){
                $('.cbt-tm-add-to-cart.loading').removeClass('loading');
                cbt.notification('Added to cart!', 'success');
            });

            $('.dropdown-menu > .widget_shopping_cart').mouseenter(function () {
                cbt.disablePageScroll();
            });
            $('.dropdown-menu > .widget_shopping_cart').mouseleave(function () {
                cbt.enablePageScroll();
            });
        },

        options_modal: function () {
            var _self = this;


           // open modal
            $('.cbt-modal-open').click(function () {
                var elm = $(this).closest( $(this).data('modal-opener-container') );

                // if already openned then do nothing
                if( $(elm).hasClass('cbt-modal-opened') 
                    || $('.cbt-modal-opened').length > 0 )
                {
                    return false;
                }

                // dont open modal of simple products
                if( $(elm).hasClass('type-product') )
                {
                    if( $('.tm-has-options',elm).length < 1 
                        && $('.variations_form',elm).length < 1 )
                    {
                        return _self.simple_product_ajax_cart(elm);
                    }
                }

                // add class to modal-opener-container which will show modal via css
                $(elm).addClass('cbt-modal-opened');
                cbt.disablePageScroll();
                
                return false;
            });

            // close modal
            $('.cbt-modal-close').click(function () {
                $(this).closest('.cbt-modal-opened').removeClass('cbt-modal-opened');
                cbt.enablePageScroll();
                return false;
            });

            // checks if user clicked on sidebar
            var clickedOnScrollbar = function(mouseX){
                // mouse position x is higher than window width minus approx scroll bar side, then its scrollbar
              if( ($(window).outerWidth() - 30) <= mouseX ){
                return true;
              }
            }

            // == Close modal on click outside
            $(document).bind( "mousedown", function(e)  // temp removed touchend cause of click though overlay issue on mobile
            {
                var container = $(".cbt-modal-opened .cbt-modal-container");
                
                // if the target of the click isn't the container nor a descendant of the container
                if (!clickedOnScrollbar(e.clientX) // ignore if clicked on sidebar
                    && !container.is(e.target) 
                    && container.has(e.target).length === 0) 
                {
                    $(container).closest('.cbt-modal-opened').removeClass('cbt-modal-opened');
                    if(cbt.scrollDisabled) cbt.enablePageScroll();
                }
            });
        },


        // simple products skip modal and adds to cart directly
        simple_product_ajax_cart: function (elm) {
            $('.cbt-tm-add-to-cart', elm).addClass('loading');

            // if has tm epo plugin options but in related products etc then take to single page
            // as fail safe
            if( $(elm).hasClass('tm-has-options') )
            {
                window.location = $('.woocommerce-LoopProduct-link', elm).attr('href');
                return false;
            }

            $('.add_to_cart_button', elm).trigger('click');
            
            return false;
        },

        // === Woocommerce Ajax add to cart for variable products ===
        // Contributors: Rcreators Websolutions
        // Stable tag: 1.2.9
        // License: GPLv2 or later
        // License URI: http://www.gnu.org/licenses/gpl-2.0.html
        variable_product_ajax_cart: function () {

            // wc_add_to_cart_params is required to continue, ensure the object exists
            if ( typeof wc_add_to_cart_params === 'undefined' )
                return false;
            
            // Ajax add to cart
            $( document ).on( 'click', '.variations_form .single_add_to_cart_button', function(e) {
                
                e.preventDefault();
                
                $variation_form = $( this ).closest( '.variations_form' );
                var var_id = $variation_form.find( 'input[name=variation_id]' ).val();
                
                var product_id = $variation_form.find( 'input[name=product_id]' ).val();
                var quantity = $variation_form.find( 'input[name=quantity]' ).val();
                
                //attributes = [];
                $( '.ajaxerrors' ).remove();
                var item = {},
                    check = true;
                    
                    variations = $variation_form.find( 'select[name^=attribute]' );
                    
                    /* Updated code to work with radio button - mantish - WC Variations Radio Buttons - 8manos */ 
                    if ( !variations.length) {
                        variations = $variation_form.find( '[name^=attribute]:checked' );
                    }
                    
                    /* Backup Code for getting input variable */
                    if ( !variations.length) {
                        variations = $variation_form.find( 'input[name^=attribute]' );
                    }
                
                variations.each( function() {
                
                    var $this = $( this ),
                        attributeName = $this.attr( 'name' ),
                        attributevalue = $this.val(),
                        index,
                        attributeTaxName;
                
                    $this.removeClass( 'error' );
                
                    if ( attributevalue.length === 0 ) {
                        index = attributeName.lastIndexOf( '_' );
                        attributeTaxName = attributeName.substring( index + 1 );
                
                        $this
                            //.css( 'border', '1px solid red' )
                            .addClass( 'required error' )
                            //.addClass( 'barizi-class' )
                            .before( '<div class="ajaxerrors"><p>Please select ' + attributeTaxName + '</p></div>' )
                
                        check = false;
                    } else {
                        item[attributeName] = attributevalue;
                    }
                
                    // Easy to add some specific code for select but doesn't seem to be needed
                    // if ( $this.is( 'select' ) ) {
                    // } else {
                    // }
                
                } );
                
                if ( !check ) {
                    return false;
                }
                
                //item = JSON.stringify(item);
                //alert(item);
                //return false;
                
                // AJAX add to cart request
                
                var $thisbutton = $( this );

                if ( $thisbutton.is( '.variations_form .single_add_to_cart_button' ) ) {

                    $thisbutton.removeClass( 'added' );
                    $thisbutton.addClass( 'loading' );

                    var data = {
                        action: 'woocommerce_add_to_cart_variable_rc',
                        product_id: product_id,
                        quantity: quantity,
                        variation_id: var_id,
                        variation: item
                    };

                    // Trigger event
                    $( 'body' ).trigger( 'adding_to_cart', [ $thisbutton, data ] );

                    // Ajax action
                    $.post( wc_add_to_cart_params.ajax_url, data, function( response ) {

                        if ( ! response )
                            return;

                        var this_page = window.location.toString();

                        this_page = this_page.replace( 'add-to-cart', 'added-to-cart' );
                        
                        if ( response.error && response.product_url ) {
                            window.location = response.product_url;
                            return;
                        }
                        
                        if ( wc_add_to_cart_params.cart_redirect_after_add === 'yes' ) {

                            window.location = wc_add_to_cart_params.cart_url;
                            return;

                        } else {

                            $thisbutton.removeClass( 'loading' );

                            var fragments = response.fragments;
                            var cart_hash = response.cart_hash;

                            // Block fragments class
                            if ( fragments ) {
                                $.each( fragments, function( key ) {
                                    $( key ).addClass( 'updating' );
                                });
                            }

                            // Block widgets and fragments
                            $( '.shop_table.cart, .updating, .cart_totals' ).fadeTo( '400', '0.6' ).block({
                                message: null,
                                overlayCSS: {
                                    opacity: 0.6
                                }
                            });

                            // Changes button classes
                            $thisbutton.addClass( 'added' );

                            // View cart text
                            if ( ! wc_add_to_cart_params.is_cart && $thisbutton.parent().find( '.added_to_cart' ).size() === 0 ) {
                                $thisbutton.after( ' <a href="' + wc_add_to_cart_params.cart_url + '" class="added_to_cart wc-forward" title="' +
                                    wc_add_to_cart_params.i18n_view_cart + '">' + wc_add_to_cart_params.i18n_view_cart + '</a>' );
                            }

                            // Replace fragments
                            if ( fragments ) {
                                $.each( fragments, function( key, value ) {
                                    $( key ).replaceWith( value );
                                });
                            }

                            // Unblock
                            $( '.widget_shopping_cart, .updating' ).stop( true ).css( 'opacity', '1' ).unblock();

                            // Cart page elements
                            $( '.shop_table.cart' ).load( this_page + ' .shop_table.cart:eq(0) > *', function() {

                                $( '.shop_table.cart' ).stop( true ).css( 'opacity', '1' ).unblock();

                                $( document.body ).trigger( 'cart_page_refreshed' );
                            });

                            $( '.cart_totals' ).load( this_page + ' .cart_totals:eq(0) > *', function() {
                                $( '.cart_totals' ).stop( true ).css( 'opacity', '1' ).unblock();
                            });

                            // Trigger event so themes can refresh other areas
                            $( document.body ).trigger( 'added_to_cart', [ fragments, cart_hash, $thisbutton ] );
                        }
                    });

                    return false;

                } else {
                    return true;
                }

            });
        }

    }

    cbt_woo.init();

});/* end of as page load scripts */
